import { Inventory } from '@/plugins/sismojs/src/types'
import { SismoazurEventParameter, State, StationStruct, StationObject } from '@/types'
import { GenericScale, SCALE_MAG, SCALE_MAG_COLOR, prettyDuration, LATENCY_SCALE, toLocalDateTime } from '@/utils'
import sismojs from '@/plugins/sismojs'
import L from 'leaflet'

const toRGB = (color: number[]) => {
  return `rgb(${color.map(c => Math.floor(c)).join(',')})`
}

const applyScale = (v: number, cs: GenericScale) => {
  if (v <= cs[0][0]) {
    return cs[0][1]
  }
  if (v >= cs[cs.length - 1][0]) {
    return cs[cs.length - 1][1]
  }
  let i
  for (i = 0; cs[i][0] < v; i++);
  const r = (v - cs[i - 1][0]) / (cs[i][0] - cs[i - 1][0])
  const result = []
  for (let j = 0; j < cs[i][1].length; j++) {
    result.push(cs[i - 1][1][j] + r * (cs[i][1][j] - cs[i - 1][1][j]))
  }
  return result
}

const processEvent = (event: SismoazurEventParameter, state: State): SismoazurEventParameter | null => {
  const now = new Date().getTime()
  if (event._po == null || event._pm == null) {
    return null
  }
  event._full_region = {}
  if (event._region != null) {
    try {
      event._full_region = JSON.parse(event._region)
    } catch {}
  }
  event._color = toRGB(applyScale(event._pm.mag.value, SCALE_MAG_COLOR))
  event._size = applyScale(event._pm.mag.value, SCALE_MAG)[0]
  if (state.fmMode) {
    event._size = 2.5 * event._size - 3
  } else if (event.type == null || event.type === '') {
    event._size = 2 * (0.8 * event._size)
  } else if (event.type === 'explosion' || event.type === 'quarry blast') {
    event._size = event._size / 3.5
  } else if (event.type === 'other event') {
    event._size = event._size * 2 * 0.8
  }
  // event._po.time._local = getLocalDateTime(event._po.time.value)
  event._po.time._local = toLocalDateTime(event._po.time.value)
  if (event._po.time._value != null) {
    event._po.time._delta = (now - event._po.time._value.getTime())
    event._po.time._pretty_delta = prettyDuration(event._po.time._delta, false)
  }
  event._po._pos = L.latLng(event._po.latitude.value, event._po.longitude.value)
  event._po._distance = state.position != null ? event._po._pos.distanceTo([state.position.coords.latitude, state.position.coords.longitude]) : null
  if (event.focal_mechanism != null && event.focal_mechanism.length > 0) {
    const fmManual = event.focal_mechanism.filter(x => x.evaluation_mode === 'manual')
    event._fm = fmManual.length > 0 ? fmManual[0] : event.focal_mechanism[0]
    event._fm._extra = {}
    if (event._fm.comment && event._fm.comment.length > 0) {
      event._fm._extra = JSON.parse(event._fm.comment[0].text)
      if (event._fm._extra != null) {
        event._fm._extra.magnitude_id = sismojs.core.event.quakeml.removeResourcePrefix(event._fm._extra.magnitude_id)
        event._fm._mag = event.magnitude.find(x => x.public_id === event._fm._extra?.magnitude_id) || event._pm
        if (event._fm._mag != null) {
          event._fm._mag.method_id = event._fm._mag.method_id?.replace('(manual)', '')
        }
      }
    }
    const [s, d, r] = [
      event._fm.nodal_planes?.nodal_plane1?.strike.value,
      event._fm.nodal_planes?.nodal_plane1?.dip.value,
      event._fm.nodal_planes?.nodal_plane1?.rake.value
    ]
    if (s != null && d != null && r != null) {
      event._fm._beachball = state.beachballEngine.getFocalImage(s, d, r, event._fm.evaluation_mode === 'manual' ? 'blue' : 'red')
    }
  }
  return event
}

export const CHECK_VERSION = (state: State) => {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', 'version')
  xhr.responseType = 'json'
  xhr.onload = () => {
    if (state.version == null) {
      state.version = xhr.response
    } else if (state.version !== xhr.response) {
      location.reload()
    }
  }
  xhr.send()
}

export const SET_EVENTS = (state: State, data: SismoazurEventParameter[]) => {
  state.loading = true
  state.beachballEngine.init().then(() => {
    // const t1 = new Date().getTime()
    const result = []
    for (const event of data) {
      if (state.fmMode) {
        if (state.fmFilter === 'oca' && event.public_id.indexOf('oca') < 0) {
          console.log(`discard event ${event.public_id}`)
          continue
        } else if (state.fmFilter === 'emsc' && event.public_id.indexOf('EMSC') < 0) {
          continue
        }
      }
      const processedEvent = processEvent(event, state)
      if (processedEvent != null) {
        if (processedEvent.public_id === 'oca2024man13') {
          console.log(processedEvent)
        }
        result.push(processedEvent)
      }
    }
    result.sort((a, b) => {
      return a._po.time.value < b._po.time.value ? -1 : 1
    })
    // const t2 = new Date().getTime()
    // console.log(`time to process events: ${t2 - t1} ms`)
    state.events = result
    state.loading = false
  })
}

export const SET_STATIONS = (state: State, data: StationStruct) => {
  const result: StationObject[] = []
  for (const [netSta, staObj] of Object.entries(data)) {
    const [net, sta] = netSta.split('_')
    staObj.network = net
    staObj.station = sta
    staObj._pos = L.latLng([staObj.latitude, staObj.longitude])
    staObj._distance = state.position != null ? staObj._pos.distanceTo([state.position.coords.latitude, state.position.coords.longitude]) : null
    if (staObj.latency != null) {
      staObj.color = toRGB(applyScale(staObj.latency, LATENCY_SCALE))
      staObj._pretty_latency = prettyDuration(staObj.latency * 1e3)
    } else {
      staObj.color = 'rgb(255,255,255)'
    }
    result.push(staObj)
  }
  state.stations = result
}

export const FOCUS_EVENT = (state: State, data: SismoazurEventParameter) => {
  if (data == null) {
    state.focusEvent = null
    return
  }
  if (!state.fmMode) {
    processEvent(data, state)
    let i = 0
    for (const event of state.events) {
      if (event.public_id === data.public_id) {
        break
      }
      i++
    }
    state.events.splice(i, 1, data)
  }
  state.focusEvent = data
}

export const FOCUS_STATION = (state: State, data: StationObject) => {
  state.focusStation = data
}

export const SET_INVENTORY = (state: State, data: Inventory) => {
  const inv = Object.assign({}, state.inventory)
  for (const [net, netObj] of Object.entries(data)) {
    if (inv[net] == null) {
      inv[net] = {}
    }
    for (const [sta, staObj] of Object.entries(netObj)) {
      inv[net][sta] = staObj
    }
  }
  state.inventory = inv
}

export const SET_LOADING = (state: State, data: boolean) => {
  state.loading = data
}

export const SET_FOCAL_MECHANISM_PARAMETERS = (state: State, data: Record<string, any>) => {
  state.fmMode = data.fmMode
  state.fmFilter = data.fmFilter
}

export const SET_FM_PARAMETERS = (state: State, data: Record<string, any>) => {
  state.fmMode = data.mode
  state.fmFilter = data.filter
}

export const CLEAR_EVENTS = (state: State) => {
  state.focusEvent = null
  state.events = []
}

export const SET_POSITION = (state: State, data: GeolocationPosition) => {
  state.position = data
  for (const event of state.events) {
    event._po._distance = state.position != null ? event._po._pos.distanceTo([state.position.coords.latitude, state.position.coords.longitude]) : null
  }
  for (const station of state.stations) {
    station._distance = state.position != null ? station._pos.distanceTo([state.position.coords.latitude, state.position.coords.longitude]) : null
  }
}

export const SET_DOCUMENT_HIDDEN = (state: State, data: boolean) => {
  state.documentHidden = data
}

export const SET_POSITION_ASKED = (state: State) => {
  state.positionAsked = true
  localStorage.setItem('positionAsked', 'true')
}

export const SET_POSITION_ACCEPTED = (state: State, data: boolean) => {
  state.positionAccepted = data
  localStorage.setItem('positionAccepted', data ? 'true' : 'false')
}
